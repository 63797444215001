#root
{
  display: flex;
  align-items: stretch;
}

#auth-form-wrapper
{
  display: flex;
  justify-content: center;
  align-items: center;
}

#sidebar
{
  background: #222222;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow: scroll;
}

#sidebar #user-icon
{
  color: white;
  font-size: 50px;
}

#main-wrapper
{
  flex: 1;
  display: flex;
  flex-direction: column;
}

#drawer-main-menu .MuiPaper-root
{
  background: #222222;
}

#workspace
{
  flex: 1;
  display: flex;
  flex-direction: column;
}

#content-wrapper
{
  height: 1px;
  flex-grow: 1;
  overflow: scroll;
}

#app-bar .app-bar-search-input
{
  margin-right: 5px;
}

#app-bar .app-bar-search-input .MuiInputBase-root
{
  background: white;
}

.test-or-development-environment #app-bar
{
  background: rgb(170, 0, 0);
}

#top h1
{
  font-size: 20px;
  margin-left: 10px;
}

#content
{
  padding: 20px 20px;
}

#content.list
{
  padding: 0;
}

#content.editor
{
  padding: 0;
  height: 100%;
}

#content.editor .tox.tox-tinymce
{
  border: none;
}

.list-data-loading,
.list-end
{
  text-align: center;
}

#main-menu
{
  padding-right: 20px;
}

#main-menu,
#main-menu .main-menu-item-icon
{
  color: white;
}

#main-menu .main-menu-item-icon
{
  min-width: 0;
  padding-right: 20px;
}

/* List filters */
.MuiList-root .available-filters-header,
.MuiList-root .filter-header
{
  text-transform: uppercase;
  background: #dddddd;
}

.MuiList-root .available-filters-header
{
  margin-bottom: 8px;
}

.MuiList-root .filter-header
{
  margin-top: 8px;
  margin-bottom: 10px;
}

/* List sort options */
.MuiList-root .sort-by-header,
.MuiList-root .sort-direction-header
{
  text-transform: uppercase;
  background: #dddddd;
  margin-bottom: 8px;
}

.MuiList-root .sort-direction-header
{
  margin-top: 8px;
}

/* Lists */
.MuiListItem-root .MuiTextField-root
{
  margin-top: 10px;
}

.MuiListItem-root .MuiListItemIcon-root
{
  min-width: 0;
  padding-right: 5px;
}

/* Data */
.data-not-found
{
  text-align: center;
}

/* Upload file control */
.upload-file
{
  display: flex;
}

.upload-file .browse
{
  margin-left: 20px;
}

/* Content manager */
.content-manager
{
  margin-top: 50px;
}

.content-manager .content-item
{
  margin-top: 20px;
  margin-bottom: 20px;
}

.content-manager .no-content
{
  margin-top: 20px;
  margin-bottom: 32px;
  border: #aaaaaa 1px dashed;
  padding: 20px;
}

.content-manager .content-item .content-item-preview
{
  border: #aaaaaa 1px dashed;
  padding: 20px;
}

.content-manager .content-item .content-item-actions
{
  display: flex;
  justify-content: end;
}

.content-manager .content-item.content-item-image
{
  display: flex;
  justify-content: center;
}

.content-manager .content-item.content-item-image .d-none
{
  display: none;
}